import React from 'react';

import './styles.scss';

export default class PortfolioMeta extends React.Component {
    render () {
        const { client, date, timeframe, action } = this.props;
        return (
            <aside className='portfolio-meta'>

                { client ? (
                    <div>
                        <h5>Client</h5>
                        { client }
                    </div>
                ) : null }

                { date ? (
                    <div>
                        <h5>Date</h5>
                        { date }
                    </div>
                ) : null }

                { timeframe ? (
                    <div>
                        <h5>Timeframe</h5>
                        { timeframe }
                    </div>
                ) : null }

                { action ? (
                    <div className='portfolio-meta-action'>
                        <a href={action.link} target='_blank' rel='noopener noreferrer'>{ action.name }</a>
                    </div>
                ) : null }

            </aside>
        );
    }
}
