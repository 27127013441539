import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout/';
import ArticleFooter from '../components/ArticleFooter/';
import PortfolioMeta from '../components/PortfolioMeta/';
import PortfolioGallery from '../components/PortfolioGallery/';

export const pageQuery = graphql`
    query PortfolioBySlug($slug: String!) {
        portfolioYaml(slug: { eq: $slug }) {
            slug
            name
            client
            date
            timeframe
            action {
                link, name
            }
            sections {
                name, text
            }
            hero {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED,
                        width: 900
                    )
                }
            }
            gallery {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED,
                        width: 900
                    )
                }
            }
        }
    }
`;

export default class PortfolioTemplate extends React.Component {

    info () {
        return this.props.data.portfolioYaml;
    }

    render () {
        const { name, sections, hero, gallery } = this.info();
        return (
            <Layout title={name}>
                <article>

                    <header>
                        <h1>{name}</h1>
                    </header>

                    <figure>
                        <GatsbyImage
                            image={getImage(hero)}
                            alt={name} />
                    </figure>

                    <PortfolioMeta {...this.info()} />

                    { sections ? sections.map((s) => (
                        <section>

                            <h2>{s.name}</h2>

                            { s.text.split(/\r?\n\r?\n/).map((l) => <p>{l}</p>) }

                        </section>
                    )) : null }

                    { gallery ? <PortfolioGallery gallery={gallery} /> : null }

                    <ArticleFooter />

                    <footer>
                        <p>Interested? <Link to='/portfolio'>See more portfolio work</Link>.</p>
                    </footer>

                </article>

            </Layout>
        );
    }

}
