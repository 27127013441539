import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './styles.scss';

export default class PortfolioGallery extends React.Component {

    constructor (props) {
        super(props);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.state = {
            open: false,
            content: null,
        };
    }

    open (slide) {
        this.setState({
            open: true,
            slide: slide,
        });
    }

    close () {
        this.setState({
            open: false,
            slide: null,
        })
    }

    render () {
        const { gallery } = this.props;
        const { open, slide } = this.state;

        return (
            <section className='portfolio-gallery'>

                <h2>Screenshots</h2>

                <div className='portfolio-gallery-images'>
                    { gallery.map((image,i) => <Thumbnail key={i} image={image} open={() => this.open(i)} />) }
                </div>

                <Modal open={open} content={<Slide image={gallery[slide]} />} close={this.close} />

            </section>
        );
    }
}

class Thumbnail extends React.Component {
    render () {
        const { image, open } = this.props;
        return (
            <button onClick={open} onKeyDown={open} className='button-unstyled'>
                <GatsbyImage image={getImage(image)}  alt='Thumbnail' className='portfolio-gallery-thumbnail' />
            </button>
        );
    }
}

class Slide extends React.Component {
    render () {
        const { image } = this.props;
        return <GatsbyImage image={getImage(image)} />
    }
}

class Modal extends React.Component {
    render () {
        const { open, content, close } = this.props;

        if (!open || !content) {
            return;
        }

        return (
            <button className='portfolio-modal-background button-unstyled' onClick={() => close()} onKeyDown={() => close()}>
                <button className='portfolio-modal-close' onClick={() => close()} onKeyDown={() => close()}>&times;</button>
                <div className='portfolio-modal-content'>
                    { content }
                </div>
            </button>
        );

    }
}
